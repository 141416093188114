import request from "@/utils/request";

const feedbackApi = {
  GetFeedbackListByPage: "/feedback/list-by-page",
  AddReply: "/feedback/add-reply",
  DeleteReply: "/feedback/del-reply",
  GetFeedbackDetail: "/feedback/info",
  ViewFeedback: "/feedback/view",
  GetFeedbackGroup: "/feedback/list-feedback-group",
};

export function getFeedbackListByPage(parameter) {
  return request({
    url: feedbackApi.GetFeedbackListByPage,
    method: "get",
    params: parameter,
  });
}

export function addReply(parameter) {
  return request({
    url: feedbackApi.AddReply,
    method: "post",
    data: parameter,
  });
}

export function deleteReply(parameter) {
  return request({
    url: feedbackApi.DeleteReply + `/${parameter.replyId}`,
    method: "post",
  });
}

export function getFeedbackDetail(parameter) {
  return request({
    url: feedbackApi.GetFeedbackDetail + `/${parameter.feedbackId}`,
    method: "get",
  });
}

export function viewFeedback(parameter) {
  return request({
    url: feedbackApi.ViewFeedback,
    method: "post",
    data: parameter,
  });
}

export function getFeedbackGroup(parameter) {
  return request({
    url: feedbackApi.GetFeedbackGroup,
    method: "get",
    params: parameter,
  });
}
