<template>
  <div v-if="!loading">
    <div>
      <a v-text="'<<返回列表'" @click="goBack"></a>
    </div>
    <div>
      <h3 class="title">问题详情</h3>
      <a-divider />
      <div class="text-area">问题描述：{{ data.msg }}</div>
      <div class="text-area">
        反馈时间：{{ moment(data.createAt).format("YYYY-MM-DD") }}
      </div>
      <div class="text-area">
        <div class="img-area">问题截图：</div>
        <div v-if="data.files.length > 0" class="img-area">
          <img
            v-for="i in data.files"
            :key="i.id"
            class="img"
            :src="fileUrl[i.id]"
            @click="clickImage(fileUrl[i.id])"
          />
        </div>
        <div v-else class="empty-area img-area">
          <a-empty description="暂无截图" />
        </div>
      </div>
    </div>
    <div v-if="hasAction('edit-feedback')">
      <h3 class="title">问题回复</h3>
      <a-divider />
      <a-textarea placeholder="请输入回复内容" :rows="4" v-model="replyMessage">
      </a-textarea>
      <div class="action-area">
        <upload-file-to-oss
          ref="uploadfile"
          type="FEEDBACK_REPLY"
          :maxQuantity="5"
          description="图片上传"
          :limitType="limitType"
        ></upload-file-to-oss>
        <a-button type="primary" @click="submit">提交</a-button>
      </div>
    </div>
    <div>
      <h3 class="title">回复内容</h3>
      <a-divider />
      <a-list item-layout="horizontal" :data-source="data.replyList">
        <a-list-item slot="renderItem" slot-scope="item">
          <a-icon
            type="delete"
            theme="filled"
            slot="actions"
            v-if="item.fromPlatform !== 'ORIGIN' && hasAction('edit-feedback')"
            @click="delReply(item.id)"
          />
          <a-list-item-meta>
            <div slot="title">
              <div class="reply-name">{{ item.fromUserName }}</div>
              <div class="reply-date">
                {{ moment(item.createAt).format("YYYY-MM-DD") }}
              </div>
            </div>
            <a-avatar slot="avatar" :src="item.avatarURL || 'https://gw.alipayobjects.com/zos/rmsportal/jZUIxmJycoymBprLOUbT.png'" />
            <div slot="description">
              <div>{{ item.msg }}</div>
              <div>
                <img
                  v-for="img in item.files"
                  :key="img.id"
                  class="reply-img"
                  :src="fileUrl[img.id]"
                  @click="clickImage(fileUrl[img.id])"
                />
              </div>
            </div>
          </a-list-item-meta>
        </a-list-item>
      </a-list>
    </div>
  </div>
</template>

<script>
import UploadFileToOss from "@/components/UploadFileToOss";
import {
  getFeedbackDetail,
  addReply,
  viewFeedback,
  deleteReply,
} from "@/api/feedback";
import { getFilePreview } from "@/api/file";
import moment from "moment";
export default {
  name: "Detail",
  components: {
    UploadFileToOss,
  },
  props: {},
  data() {
    return {
      loading: true,
      feedbackId: parseInt(this.$route.query.feedbackId),
      uploadFileIds: [],
      replyMessage: null,
      data: {
        fileIds: "",
      },
      limitType: ["jpg", "bmp", "png", "gif", "jpeg"],
      replyList: [
        {
          fromUserName: "testname",
          createAt: "2022-04-01T09:00:36.595Z",
          fileIds: "",
          msg: "问题回复",
          fromPlatform: "",
        },
      ],
      fileIds: [],
      fileUrl: {},
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    moment,
    loadData() {
      this.uploadFileIds = [];
      this.fileIds = [];
      this.replyMessage = null;
      this.fileUrl = {};
      this.loading = true;

      viewFeedback({ feedbackId: this.feedbackId, fromPlatform: "ADMIN" });
      getFeedbackDetail({ feedbackId: this.feedbackId }).then((res) => {
        this.data = res.data;
        this.fileIds = this.data.files.map((item) => item.id);
        if (this.data.replyList.length > 0) {
          this.data.replyList.forEach((item) => {
            this.fileIds = this.fileIds.concat(item.files.map((i) => i.id));
          });
        }
        if (this.fileIds.length > 0) {
          getFilePreview({ fileId: this.fileIds.join(","), protocol:window.location.protocol }).then((res) => {
            res.data.forEach((item) => {
              this.fileUrl[item.id] = item.url;
            });
            this.loading = false;
          });
        } else {
          this.loading = false;
        }
      });
    },
    clickImage(url) {
      this.$dialog(
        {
          render() {
            return <img alt="example" style="width: 100%" src={url} />;
          },
        },
        {},
        {
          width: "60vw",
          footer: "",
          centered: true,
          maskClosable: false,
        }
      );
    },
    submit() {
      addReply({
        feedbackId: this.feedbackId,
        msg: this.replyMessage,
        fromPlatform: "ADMIN",
      }).then((res) => {
        this.$refs.uploadfile.handleSave(res.data.id).then(() => {
          this.loadData();
        });
      });
    },
    delReply(id) {
      deleteReply({ replyId: id }).then(() => {
        this.loadData();
      });
    },
    goBack() {
      this.$router.push({
        path: "/system/feedback/list",
      });
    },
  },
};
</script>

<style scoped>
.title {
  margin-top: 24px;
}
.ant-divider-horizontal {
  margin-top: 0px;
  margin-bottom: 12px;
}
.ant-form-item {
  margin-bottom: 0px;
}
.img {
  max-width: 14vw;
  margin: 0.2vw;
}
.reply-img {
  max-width: 12vw;
  margin: 0.2vw;
}
.action-area {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
.reply-name {
  font-size: 15px;
}
.reply-date {
  font-size: 4px;
}
.empty-area {
  width: 400px;
  height: 100px;
}
.text-area {
  margin-top: 10px;
}
.img-area {
  display: inline-block;
  vertical-align: top;
}
</style>